export const ar = {
  "Latest posts": "آخر المشاركات",

  No: "رقم",
  Image: "صورة",
  Title: "العنوان",
  Date: "التاريخ",
  Category: "المواضيع",
  Publisher: "المنشور",
  Status: "الحالة",
  "Latest orders": "الطلبات الاخيرة",
  User: "المستخدمين",
  Post: "المنشورات",
  AD: "الإعلانات",
  Income: "الدخل",
  News: "الأخبار",
  No: "الرقم",
  Image: "الصورة",
  Title: "العنوان",
  Date: "التاريخ",
  Category: "القسم",
  ID: "الرمز",
  Publisher: "منشور",
  Status: "الحالة",
  Action: "الإجراء",
  Reject: "مرفوض",
  Published: "منشور",
  "In-Review": "يتم المراجعة",



  View: "عرض",
  Edit: "تعديل",
  Delete: "حذف",



  Interval: "Interval",
  Price: "المبلغ",
  "Stat Date": "تاريخ البدء",
  "End Date": "تاريخ الانتهاء",
  Status: "الحالة",
  Action: "تعديل",
  Name: "الاسم",
  Save: "حفظ",
  Edit: "تعديل",
  Delete: "حذف",
  Home: "الرئيسية",
  Posts: "المنشورات",
  Ads: "الاعلانات",
  Users: "المستخدمين",
  Finance: "المالية",
  Banner: "الشرائح",
  Notification: "الاشعارات",
  Staff: "الموظفين",
  Setting: "الاعدادات",
  "Approval requests": "طلبات الموافقة",
  Posts: "المنشورات",
  Add: "إضافة",
  "Add Post": "اضافة منشور",
  url: "الرابط",
  "Start date": "تاريخ البدء",
  "End Date": "تاريخ الانتهاء",
  "Created at": "تم الانشاء في",
  "End date": "تاريخ الانتهاء",
  "Total amount": "المبلغ الاجمالي",
  "Payment status": "حالة الدفع",
  Description: "الوصف",
  Type: "النوع",
  "Publishing Add": "إضافة النشر",
  "User type": "نوع المستخدم",
  id: "الرمز",
  Duration: "المدة",
  "Ad Id": "رمز الاعلان",
  "User name": "إسم المستخدم",
  "Transaction date": "تاريخ التحويل",
  "User phone": "رقم الهاتف",
  "Send Notification": "إرسال اشعار",
  "Dashboard": 'لوحة التحكم',
  "Add Staff": "اضافة موظف",


  // "Careers": "وظائف",
  // "News": "الأخبار",
  // "His Majesty Photo": "المقام السامي",
  // "Decrees": "مراسيم",
  // "Omani projects": "مشاريع عمانية",
  // "Tourism in Oman": "السياحة في عمان",
  // "Disaster awareness": "التوعية عند الكوارث",
  // "Omani history": "التاريخ العماني",
  // "Bave men of Oman": "رجال عمان الاشاوس",
  // "Oman Vision 2040": "رؤية عمان ٢٠٤٠",
  // "Sports in Oman": "الرياضة في عمان",
  // "Omani personalities": "شخصيات عمانية",
  // "Palestinian cause": "القضية اللسطينية",

  "Careers": "وظائف",
  "News": "الأخبار",
  "His Majesty Photo": "صور المقام السامي",
  "Decrees": "مراسيم",
  "Omani projects": "مشاريع عمانية",
  "Tourism in Oman": "السياحة في عمان",
  "Disaster awareness": "التوعية عند الكوارث",
  "Omani history": "التاريخ العماني",
  "Bave men of Oman": "رجال عمان الاشاوس",
  "Oman Vision 2040": "رؤية عمان ٢٠٤٠",
  "Sports in Oman": "الرياضة في عمان",
  "Omani personalities": "شخصيات عمانية",
  "Palestinian cause": "القضية اللسطينية",



  "وظائف ": "Careers",
  "الأخبار": "News",
  "صور المقام السامي": "His Majesty Photos",
  "مراسيم ": "Decrees",
  "مشاريع عمانية": "Omani projects",
  "السياحة في عمان ": "Tourism in Oman",
  "التوعية عند الكوارث ": "Disaster awareness",
  "التاريخ العماني": "Omani history",
  "رجال عمان الاشاوس ": "Brave men of Oman",
  "رؤية عمان ٢٠٤٠": "Oman Vision 2040",
  "الرياضة في عمان ": "Sports in Oman",
  "شخصيات عمانية": "Omani personalities",
  "القضية الفلسطينية": "Palestinian cause",


  'Food': 'مطاعم',
  'Hotels': 'فنادق',
  'Discounts': 'تخفيضات',
  'Property': 'عقارات',
  'مطاعم': 'Food',
  'فنادق': 'Hotels',
  'تخفيضات': 'Discounts',
  'عقارات': 'Properties',
  

  // 'Food': 'مطاعم',
  // 'Hotels': 'فنادق',
  // 'Discounts': 'تخفيضات',
  // 'Property': 'عقارات',


  // 'مطاعم': 'Food',
  // 'فنادق': 'Hotels',
  // 'تخفيضات': 'Discounts',
  // 'عقارات': 'Property',


  // "Tax": "ضريبة",
  // 'Select category': 'أختر الموضوع',
  // 'Ad information': 'معلومات الأعلان',
  // 'Ad video or image': 'أضف صورة أو فيديو',
  // 'Image or Video': 'صورة أو فيديو',
  // 'Posted by': 'نشر بواسطة',
  // "1 Month": "شهر",
  // "3 Months": "٣ أشهر",
  // "6 Months": "٦ أشهر",
  // "1 Year": "سنة",
  // "Pending": "معلق",
  // "Rejected": "مرفوض",
  // "Approved": "موافقة",
  // "Paid": "مدفوع",
  // "Unpaid": "غير مدفوع",
  // "Approve": "موافقة",
  // "Rejected Reason": "سبب الرفض",
  // "Enter rejected reason": "أدخل سبب الرفض",
  // "Rejected reason": "سبب الرفض",
  // "Reject": "رفض",
  // "Add Image & Video": "أضف الصورة أو الفيديو",
  // "Enter post title": "أدخل عنوان المنشور",
  // "Enter Post description": "أدخل وصف المنشور",
  // "Publishing Posts": "نشر المشاركات",
  // "Phone": "هاتف",
  // 'Id': 'تعريف',
  // "Are you sure you want to delete the record ?": "هل أنت متأكد أنك تريد حذف السجل؟",
  // "Cancel": "يلغي",
  // "Yes": "نعم",

  "Tax": "ضريبة",
  'Select category': 'اختيار الموضوع',
  'Ad information': 'معلومات الاعلان',
  'Ad video or image': 'اضف صورة او فيديو',
  'Image or Video': 'صورة او فيديو',
  'Posted by': 'نشر من قبل',
  "1 Month": "شهر",
  "3 Months": "٣ شهور",
  "6 Months": "٦ شهور",
  "1 Year": "سنه",
  "Pending": "معلق",
  "Rejected": "مرفوض",
  "Approved": "موافقة",
  "Paid": "مدفوع",
  "Unpaid": "غير مدفوع",
  "Ad details": "بيانات الاعلان",
  "Approve": "موافقة",
  "Rejected Reason": "سبب الرفض",
  "Enter rejected reason": "ادخل سبب الرفض",
  "Rejected reason": "سبب الرفض",
  "Reject": "رفض",
  "Add Image & Video": "اضف صورة & فديو",

  "Enter post title": "عنوان المنشور",
  "Enter Post description": "محتوى المنشور",
  "rejected": "مرفوض",
  "published": "تم النشر",
  "in-review": "قيد المراجعة",
  "Post details": "تفاصيل المنشور ",
  "Publishing Posts": "الاخبار المنشورة",
  "Phone": "هاتف",
  'Id': 'تعريف',
  "Are you sure you want to delete the record ?": "متاكد بانك تريد حذف هذه التسجيل?",
  "Cancel": "الغاء",
  "Yes": "نعم",
  "Active": "تفعيل",
  "Inactive": "غير مفعل",
  "Transaction id": "رمز التحويل",
  "Start/End date": "تاريخ البدء/الانتهاء",

  "Enter description": "أدخل الوصف",
  "Published": "نشرت",
  "Rejected": "مرفوض",
  "In-review": "في مراجعة",
  "View Ad": "عرض الإعلان",
  "Enter post url": "أدخل عنوان URL للنشر",
  "Edit Banner": "تحرير الشريحة",
  "Add Banner": "إضافة شريحة",
  "Company Whatsapp": "رقم الواتساب",
  "Company X": "X حساب منصة",
  "Company Instagram": "حساب الأنستغرام",
  "Terms & Privacy Settings": "الشروط وإعدادات الخصوصية",
  "Company Settings": "إعدادات الشركة",
  "Company Title": "عنوان الشركة",
  "Support Name": "إسم الشركة",
  "Support Email": "البريد الإلكتروني للدعم الفني",
  "Mobile": "رقم هاتف النقال",
  "Select Time Zone": "اختر المجال الزمني",
  "Max Serviceable Distance (in Kms)": "أقصى مسافة للخدمة (بالكيلومترات)",
  "Country Code": "رقم الهاتف",
  "Primary Color": "لون أصلي",
  "Secondary Color": "اللون الثانوي",
  "Primary Shadow Color": "لون الظل الأساسي",
  "OTP System": "نظام كلمة مرور لمرة واحدة  ",
  "Login Image": "صورة تسجيل الدخول",
  "Latest posts": "آخر المشاركات",
  "Add user": "إضافة مستخدم",
  "Password": "كلمة المرور",
  "Enter Phone": "أدخل الهاتف",
  "Enter password": "أدخل كلمة المرور",
  "Enter Name": "أدخل الاسم",
  "Email": "البريد إلكتروني",
  "Update": "تحديث",
  "Profile": 'حساب تعريفي',
  'Ad settings': 'إعدادات الإعلان',
  "Price/Month": 'السعر/الشهر',
  "Price/3 Months": 'السعر/3 أشهر',
  "Price/6 Months": 'السعر/6 أشهر',
  "Price/Year": 'السعر/السنة',
};
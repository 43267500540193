import React from "react";

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.965 7.789c.298-3.46 2.076-4.874 5.969-4.874h.125c4.296 0 6.017 1.72 6.017 6.017V15.2c0 4.297-1.72 6.018-6.017 6.018h-.125c-3.864 0-5.642-1.394-5.96-4.797M14.828 12.056H3.89M6.033 8.836l-3.22 3.22 3.22 3.22"
      ></path>
    </svg>
  );
}

export default LogoutIcon;

import React, { useEffect, useState } from "react";
import { CrossIcon, EditIcon, ImageIcon } from "../../assets/Icons";

import { routes } from "../../Api/routes";
import { getRequest, postRequest, postRequestFormData } from "../../Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Layout } from "../../components";
import { IMAGE_URL } from "../../Api/constants";
import { useNavigate } from "react-router-dom";
import { Image } from "antd";


const MyProfile = ({
}) => {
    const words = useSelector((state) => state.authReducer.words);
    const lang = useSelector((state) => state.authReducer.lang);
    const navigate = useNavigate();
    const textAlign = lang == 'ar' ? 'text-right' : 'text-left';


    const [user, setUser] = useState();

    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState("");

    const [img, setImg] = useState();
    const [localImg, setLocalImg] = useState();
    const [isEditing, setIsEditing] = useState(false);



    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        // localStorage.setItem(null);
        // dispatch(setAdminToken(null));
        window.location.reload();
        navigate("/");
    };
    const doUpdate = async () => {

        const onSuccess = (res) => {

            if (res.user) {
                toast.success('Profile updated successfully');
                let val = res.user
                setEmail(val.email);
                setPhone(val.phone);
                setImg(val.image);
                localStorage.setItem('user', JSON.stringify(res.user));
                setIsEditing(false);
                const admin = JSON.parse(localStorage.getItem("admin"));
                const body = {
                    ...admin,
                    email: val.email,
                    image: val.image,
                    phone: val.phone
                };

                localStorage.setItem('admin', JSON.stringify(body));




            }
            else logout();
            setLoading(false)
        };
        const onError = (err) => {
            console.log("errororor", err);
            setLoading(false);
             toast.error(typeof err.error == 'string' ?  err.error: "Something went wrong. Please try again later");
        };
        const body = {
            email: email,
            number: phone?.toString(),
            password: password,
            image: localImg ?? null,
        };
        setLoading(true);
        await postRequestFormData(body, routes.updateProfile, true, onSuccess, onError);
    };

    const getProfile = async () => {

        const onSuccess = (res) => {
            setLoading(false);
            const user = res.user;
            localStorage.setItem('user', JSON.stringify(res.user));
            setEmail(user.email);
            setPhone(user.phone);
            setImg(user.image);
        };
        const onError = (err) => {
            setLoading(false);
        };

        setLoading(true);
        await getRequest('', routes.getProfile, true, onSuccess, onError);
    }

    useEffect(() => {

        const adminDataString = localStorage.getItem("user");
        let val = null;
        if (adminDataString) {
            try {
                val = JSON.parse(adminDataString);
                setUser(val);
                setPhone(val.phone);
                setEmail(val.email);
                setImg(val.image);
            } catch (error) {
                getProfile();
                // Handle parsing error (if the stored value is not valid JSON)
                console.error('Error parsing admin data:', error);
            }
        }
        else getProfile();




    }, [])

    return (
        <Layout pageTitle={words["Profile"]} >
            <div
                style={{ width: "50%", marginLeft: 10, borderRadius: 5 }}
                dir={lang == 'ar' ? "rtl" : 'ltr'}
                className="flex flex-col shadow-md-custom border border-[#D8D8D8]"
            >
                <div style={{ width: "100%", height: 5, backgroundColor: '#8434FC', borderTopRightRadius: 5, borderTopLeftRadius: 5 }} />
                <div
                    onClick={() => {
                        !isEditing && setIsEditing(true)

                    }}
                    className="hover:cursor-pointer"
                    style={{ alignSelf: 'flex-end', marginTop: 20, marginRight: 20, marginLeft: 20, zIndex: 20 }}>
                    {
                        isEditing ?
                            <>

                                <div
                                    onClick={() => setIsEditing(false)}
                                    className={`${textAlign} text-black text-sm font-normal`}>Cancel</div>
                            </>
                            :
                            <EditIcon />}

                </div>
                <div className="flex flex-col gap-5 mt-10  p-4">

                    <div
                        style={{ margin: 'auto' }}
                        className="img-box flex flex-col items-center justify-center h-28 w-28 rounded-full cursor-pointer bg-[#D9D9D9] relative"
                        onClick={() => {
                            if (!isEditing) return;
                            document.getElementById("upload_img").click()
                        }}
                    >
                        {localImg ? (
                            <img
                                alt="img"
                                src={URL.createObjectURL(localImg)}
                                className="h-full w-full rounded-full object-contain"
                            />
                        ) : (
                            <Image
                                // alt="banner image"
                                src={IMAGE_URL + img}
                                className="h-full w-full rounded-full object-contain"
                            />
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            title="image"
                            id="upload_img"
                            className="hidden cleanbtn"
                            onChange={(e) => {
                                setLocalImg(e.target.files[0]);
                            }}
                        />
                        {
                            isEditing &&

                            <div className="flex items-center justify-center absolute bg-white border border-themeColor h-8 w-8 p-[6px] rounded-full bottom-0 right-0 z-40">
                                <EditIcon />
                            </div>
                        }
                    </div>

                    <div className="flex flex-col gap-2">
                        <label className={`${textAlign} text-black text-sm font-normal`}>
                            {words["Phone"]}
                        </label>
                        <input
                            disabled={!isEditing}
                            onChange={(e) => setPhone(e.target.value)}
                            type="number"
                            // placeholder={words["Enter Phone"]}
                            value={phone}
                            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className={`${textAlign} text-black text-sm font-normal`}>
                            {words["Email"]}
                        </label>
                        <input
                            disabled={!isEditing}
                            onChange={(e) => setEmail(e.target.value)}
                            // type="email"
                            placeholder={words["Enter email"]}
                            value={email}
                            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
                        />
                    </div>
                    {
                        isEditing &&

                        <div className="flex flex-col gap-2">
                            <label className={`${textAlign} text-black text-sm font-normal`}>
                                {words["Password"]}
                            </label>
                            <input
                                disabled={!isEditing}
                                onChange={(e) => setPassword(e.target.value)}
                                // type="password"
                                placeholder={words["Enter password"]}
                                className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
                            />
                        </div>
                    }
                    {/* <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">
            {words["User type"]}
          </label>
          <input
            onChange={(e) => setType(e.target.value)}
            type="text"
            placeholder="Enter User type "
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div> */}
                    {
                        isEditing &&
                        <div className="flex items-center justify-center">
                            <button
                                onClick={() => (loading ? null : doUpdate())}
                                className="button rounded-full !py-2 !px-10"
                            >
                                {loading ? (
                                    <svg
                                        aria-hidden="true"
                                        class="inline w-8 h-8 text-gray-200 animate-spin dark:text-white-600 fill-blue-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                ) : (
                                    words["Update"]
                                )}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default MyProfile;




const storeIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.89337 10.7852V15.1012C2.89337 19.4172 4.62362 21.1475 8.93963 21.1475H14.1208C18.4368 21.1475 20.167 19.4172 20.167 15.1012V10.7852" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.535 11.535C13.2941 11.535 14.5917 10.1027 14.4187 8.34364L13.7843 1.92249H9.29526L8.65122 8.34364C8.4782 10.1027 9.77588 11.535 11.535 11.535Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.6005 11.535C19.5422 11.535 20.9648 9.95854 20.7726 8.02642L20.5034 5.38299C20.1574 2.88374 19.1961 1.92249 16.6777 1.92249H13.7458L14.4187 8.66085C14.5821 10.2469 16.0144 11.535 17.6005 11.535Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.42146 11.535C7.00752 11.535 8.43979 10.2469 8.59359 8.66085L8.80506 6.53649L9.26646 1.92249H6.33465C3.81618 1.92249 2.85492 2.88374 2.50887 5.38299L2.24934 8.02642C2.05709 9.95854 3.47974 11.535 5.42146 11.535Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.535 16.3412C9.92973 16.3412 9.1319 17.1391 9.1319 18.7444V21.1475H13.9381V18.7444C13.9381 17.1391 13.1403 16.3412 11.535 16.3412Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

const seminarIcon = () => {
    return (
        <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1152 10.8892C20.0673 10.8452 20.0196 10.8014 19.975 10.7604V3.4935C20.0202 3.45145 20.0687 3.40643 20.1174 3.36123C20.2139 3.27174 20.3106 3.18238 20.385 3.11416C20.4225 3.07984 20.4528 3.05225 20.4741 3.03312L20.4752 3.03213C20.8304 2.79836 21.2964 2.76115 21.6998 2.94837C22.1073 3.13755 22.32 3.49091 22.32 3.8543V10.3949C22.32 10.7578 22.1059 11.1123 21.6998 11.3008C21.5279 11.3803 21.3435 11.4197 21.161 11.4197C20.9162 11.4197 20.6776 11.35 20.4747 11.2167L20.4729 11.215C20.4513 11.1958 20.4208 11.1683 20.3833 11.1343C20.3088 11.0665 20.2119 10.9779 20.1152 10.8892ZM20.4944 3.01506C20.5025 3.00812 20.5011 3.00957 20.4937 3.01569L20.4944 3.01506ZM20.4938 11.2335C20.5009 11.2394 20.5022 11.2408 20.4941 11.2338L20.4938 11.2335ZM12.4963 13.5483H4.80625C2.51785 13.5483 0.75 11.8291 0.75 9.83012V4.46823C0.75 2.46923 2.51785 0.75 4.80625 0.75H12.4963C14.7846 0.75 16.5525 2.46923 16.5525 4.46823V9.83012C16.5525 11.8291 14.7846 13.5483 12.4963 13.5483Z" stroke="white" stroke-width="1.5" />
        </svg>

    )
}

const ageIcon = () => (
    <svg width="20" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3334 3.33337V8.33337" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M26.6666 3.33337V8.33337" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M26.6667 5.83337C32.2167 6.13337 35 8.25004 35 16.0834V26.3834C35 33.25 33.3333 36.6834 25 36.6834H15C6.66667 36.6834 5 33.25 5 26.3834V16.0834C5 8.25004 7.78333 6.15004 13.3333 5.83337H26.6667Z" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M34.5833 29.3334H5.41663" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M20 13.75C17.95 13.75 16.2167 14.8667 16.2167 17.0333C16.2167 18.0667 16.7 18.85 17.4334 19.35C16.4167 19.95 15.8334 20.9167 15.8334 22.05C15.8334 24.1167 17.4167 25.4 20 25.4C22.5667 25.4 24.1667 24.1167 24.1667 22.05C24.1667 20.9167 23.5834 19.9333 22.55 19.35C23.3 18.8333 23.7667 18.0667 23.7667 17.0333C23.7667 14.8667 22.05 13.75 20 13.75ZM20 18.4833C19.1334 18.4833 18.5 17.9667 18.5 17.15C18.5 16.3167 19.1334 15.8333 20 15.8333C20.8667 15.8333 21.5 16.3167 21.5 17.15C21.5 17.9667 20.8667 18.4833 20 18.4833ZM20 23.3333C18.9 23.3333 18.1 22.7833 18.1 21.7833C18.1 20.7833 18.9 20.25 20 20.25C21.1 20.25 21.9 20.8 21.9 21.7833C21.9 22.7833 21.1 23.3333 20 23.3333Z" fill="#979797" />
    </svg>
)

const genderIcon = () => (
    <svg width="20" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.0833 35.8333C24.217 35.8333 30 30.0503 30 22.9167C30 15.783 24.217 10 17.0833 10C9.94962 10 4.16663 15.783 4.16663 22.9167C4.16663 30.0503 9.94962 35.8333 17.0833 35.8333Z" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M35.8333 4.16663L26.6666 13.3333" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M25 4.16663H35.8333V15" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)

const hobbiesIcon = () => (
    <svg width="20" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.15 22.1334H15.3V34.1334C15.3 36.9334 16.8166 37.5 18.6666 35.4L31.2833 21.0667C32.8333 19.3167 32.1833 17.8667 29.8333 17.8667H24.6833V5.8667C24.6833 3.0667 23.1666 2.50003 21.3166 4.60003L8.69997 18.9334C7.16663 20.7 7.81663 22.1334 10.15 22.1334Z" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const postsIcon = () => (
    <svg width="20" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 36.6666H25C33.3334 36.6666 36.6667 33.3333 36.6667 24.9999V14.9999C36.6667 6.66659 33.3334 3.33325 25 3.33325H15C6.66671 3.33325 3.33337 6.66659 3.33337 14.9999V24.9999C3.33337 33.3333 6.66671 36.6666 15 36.6666Z" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M29.1667 28.4666H26.0834" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21.6167 28.4666H10.8334" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M29.1666 22.2H19.95" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15.45 22.2H10.8334" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const followersIcon = () => (
    <svg width="20" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 11.9333C29.9 11.9166 29.7834 11.9166 29.6834 11.9333C27.3834 11.8499 25.55 9.96659 25.55 7.63325C25.55 5.24992 27.4667 3.33325 29.85 3.33325C32.2334 3.33325 34.15 5.26659 34.15 7.63325C34.1334 9.96659 32.3 11.8499 30 11.9333Z" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M28.2834 24.0666C30.5667 24.4499 33.0834 24.0499 34.8501 22.8666C37.2001 21.2999 37.2001 18.7333 34.8501 17.1666C33.0667 15.9833 30.5167 15.5833 28.2334 15.9833" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.95005 11.9333C10.0501 11.9166 10.1667 11.9166 10.2667 11.9333C12.5667 11.8499 14.4 9.96659 14.4 7.63325C14.4 5.24992 12.4834 3.33325 10.1001 3.33325C7.71672 3.33325 5.80005 5.26659 5.80005 7.63325C5.81672 9.96659 7.65005 11.8499 9.95005 11.9333Z" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.6667 24.0666C9.38336 24.4499 6.86669 24.0499 5.10002 22.8666C2.75002 21.2999 2.75002 18.7333 5.10002 17.1666C6.88336 15.9833 9.43335 15.5833 11.7167 15.9833" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M19.9999 24.3835C19.8999 24.3668 19.7833 24.3668 19.6833 24.3835C17.3833 24.3001 15.5499 22.4168 15.5499 20.0835C15.5499 17.7001 17.4666 15.7834 19.8499 15.7834C22.2333 15.7834 24.1499 17.7168 24.1499 20.0835C24.1333 22.4168 22.2999 24.3168 19.9999 24.3835Z" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15.15 29.6333C12.8 31.2 12.8 33.7667 15.15 35.3333C17.8166 37.1167 22.1833 37.1167 24.85 35.3333C27.2 33.7667 27.2 31.2 24.85 29.6333C22.2 27.8667 17.8166 27.8667 15.15 29.6333Z" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const pointsIcon = () => (
    <svg width="20" height="42" viewBox="0 0 37 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.2342 9.90415L20.5272 13.0002C20.6149 13.2695 20.856 13.4265 21.097 13.4489L24.3845 13.763C25.0858 13.8303 25.3488 14.7053 24.8447 15.1765L22.3682 17.3976C22.1709 17.5771 22.0833 17.8463 22.149 18.0931L22.8722 21.3911C23.0257 22.0866 22.3024 22.625 21.6888 22.266L18.8616 20.5385C18.6424 20.4039 18.3575 20.4039 18.1384 20.5385L15.3112 22.266C14.7195 22.625 13.9743 22.0866 14.1277 21.3911L14.851 18.0931C14.9167 17.8463 14.829 17.5771 14.6318 17.3976L12.1553 15.1765C11.6293 14.7053 11.9142 13.8303 12.6155 13.763L15.9029 13.4489C16.1659 13.4265 16.3851 13.2695 16.4947 13.0227L17.7877 9.92658C18.0507 9.25352 18.9493 9.25352 19.2342 9.90415Z" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M33.4082 16.4661C33.4648 8.04043 26.8385 1.16305 18.6078 1.10506C10.3772 1.04708 3.65896 7.83044 3.60232 16.2561C3.54567 24.6818 10.172 31.5592 18.4027 31.6172C26.6334 31.6752 33.3516 24.8918 33.4082 16.4661Z" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.4728 31.4651L12.7689 41.0001L8.0789 35.5034L1 36.1765L5.69004 24.1511" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M20.5055 31.4651L24.2312 41.0001L28.8994 35.5034L36.0002 36.1765L31.2882 24.1511" stroke="#979797" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)

export const Icons = {
    storeIcon,
    seminarIcon,
    ageIcon,
    genderIcon,
    hobbiesIcon,
    postsIcon,
    followersIcon,
    pointsIcon,
}
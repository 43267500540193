import "firebase/firestore";
import { initializeApp } from 'firebase/app';
import {
  getFirestore, collection, getDocs,
  addDoc, deleteDoc, doc, updateDoc,
  setDoc
} from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBn85sYGkpTwnN5F_tjizDNtHmQFw-chwI",
  authDomain: "magicpagessoical-ir4lr4.firebaseapp.com",
  projectId: "magicpagessoical-ir4lr4",
  storageBucket: "magicpagessoical-ir4lr4.appspot.com",
  messagingSenderId: "439088226290",
  appId: "1:439088226290:web:f3da4db068aec30d49156b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);
const storage = getStorage(app);

const uploadFile = async (file, filePath) => {
  const fileRef = ref(storage, filePath);
  const snapshot = await uploadBytes(fileRef, file);
  const downloadURL = await getDownloadURL(snapshot.ref);
  return downloadURL;
};


export {
  firestore, collection, getDocs, addDoc, deleteDoc, doc,
  updateDoc, storage, uploadFile, deleteObject, ref as storageRef,setDoc
};



// rules_version = '2';
// service firebase.storage {
//   match /b/{bucket}/o {
//     match /{allPaths=**} {
//       allow read, write: if false;
//     }
//     match /users/{userId}/{allPaths=**} {
//       allow read: if true;
//       allow write: if request.auth.uid == userId;
//     }
//   }
// }
import React from "react";

function LangSignIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.99 7.96H6.01M11 6.28v1.68M13.5 7.94c0 4.3-3.36 7.78-7.5 7.78M16 15.72c-1.8 0-3.4-.96-4.55-2.47"
      ></path>
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 21h6c5 0 7-2 7-7V8c0-5-2-7-7-7H8C3 1 1 3 1 8v6c0 5 2 7 7 7z"
      ></path>
    </svg>
  );
}

export default LangSignIcon;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import TextEditor from "../../components/TextEditor";
import {
  useGetSettingQuery,
  useCreateSettingMutation,
} from "../../store/services/settingService";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../Api/constants";
import AppLoader from '../../components/Loader/AppLoader';
import { useSelector } from "react-redux";
import { collection, firestore, getDocs, addDoc, updateDoc, doc, setDoc } from "../../firebase";


const Setting = () => {

  const words = useSelector((state) => state.authReducer.words);
  const [activeTab, setActiveTab] = useState("setting");
  const [formData, setFormData] = useState({
    company_title: "",
    company_address: "",
    support_name: "",
    support_email: "",
    company_mobile: "",
    time_zoone: "",
    max_services_distance: "",
    // country_code: "",
    primary_color: "",
    secondary_color: "",
    paimary_shadow_color: "",
    otp_system: "",
    // cancel_duration: "",
    company_whatsapp: '',
    company_X: '',
    company_instagram: '',
  });

  const [loading, setLoading] = useState(false);

  const [custPrivacy, setCustPrivacy] = useState('');
  const [custTerms, setCustTerms] = useState('');
  const [storePrivacy, setStorePrivacy] = useState('');
  const [storeTerms, setStoreTerms] = useState('');

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };




  const handleSubmitSetting = async (e) => {

    setLoading(true)
    const data = {
      company_title: formData.company_title,
      support_name: formData.support_name,
      support_email: formData.support_email,
      company_mobile: formData.company_mobile,
      time_zoone: formData.time_zoone,
      max_services_distance: formData.max_services_distance,
      // country_code: formData.country_code,


      company_address: formData.company_address,

      privacy_policy: custPrivacy,
      terms_condition: custTerms,
      store_terms_condition: storeTerms ?? null,
      store_privacy_policy: storePrivacy ?? null,
      company_whatsapp: formData.company_whatsapp,
      company_X: formData.company_X,
      company_instagram: formData.company_instagram,
    }

    const collectionRef = doc(firestore, 'settings', "companySettings");
    await setDoc(collectionRef, data, { merge: true }); // merge: true allows partial updates
    toast.success('Settings updated successfully!');
    // await addDoc(collectionRef, data);
    setLoading(false)



  };

  const getSettings = async () => {
    try {
      setLoading(true)
      const collectionRef = collection(firestore, 'settings');
      const snapshot = await getDocs(collectionRef);
      const docs = snapshot.docs.map(doc => {
        return {
          ...doc.data(),
        }
      });
      console.log('data', docs)
      setLoading(false);
      if (docs?.length) {
        setFormData(docs[0]);
        setCustPrivacy(docs[0].privacy_policy)
        setCustTerms(docs[0].terms_condition)
      }

    }
    catch (err) {
      setLoading(false)
      toast.error(err);
    }

  }


  useEffect(() => {
    getSettings();
  }, []);



  return (
    <>

      {
        loading && <AppLoader />
      }
      <Layout pageTitle={words["Setting"]}>
        <div className="setting-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">

            <div className="flex flex-col gap-5 mt-6">
              <div
                style={{ borderWidth: 1, }}
                className="flex w-full border-b-2 border-b-themeColor border-transparent ">
                <button
                  style={{marginLeft:-3,marginBottom:-1}}
                  className={`btn  font-semibold rounded ${activeTab === "setting" ? " bg-themeColor text-white" : "text-themeColor"
                    }`}
                  onClick={(e) => setActiveTab("setting")}
                >
                  {words["Setting"]}
                </button>
                <button
                  className={`btn font-semibold rounded ${activeTab === "termsPrivacy" ? "bg-themeColor text-white" : "text-themeColor"
                    }`}
                  onClick={(e) => setActiveTab("termsPrivacy")}
                >
                  {words['Terms & Privacy Settings']}
                </button>
              </div>
              {activeTab === "setting" ? (
                <>
                  <div className="setting-grid w-full grid md:grid-cols-1 sm:grid-cols-2 grid-cols-1 gap-4 my-8">


                    <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3">



                      <h1 className="text-black text-base font-medium ">
                        {words['Company Settings']}
                      </h1>


                      <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                      <div className="flex flex-col gap-5">
                        <div className="grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-x-3 gap-y-4">
                          <div className="flex flex-col gap-1">
                            <label className="text-black text-sm font-medium">
                              {words['Company Address']}
                            </label>
                            <input
                              type="text"
                              name="company_address"
                              value={formData.company_address}
                              onChange={handleInput}
                              className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <label className="text-black text-sm font-medium">
                              {words['Company Title']}
                            </label>
                            <input
                              type="text"
                              name="company_title"
                              value={formData.company_title}
                              onChange={handleInput}
                              className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <label className="text-black text-sm font-medium">
                              {words['Support Name']}
                            </label>
                            <input
                              type="text"
                              name="support_name"
                              value={formData.support_name}
                              onChange={handleInput}
                              className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                            />
                          </div>

                          <div className="flex flex-col gap-1">
                            <label className="text-black text-sm font-medium">
                              {words['Mobile']}
                            </label>
                            <input
                              type="text"
                              name="company_mobile"
                              value={formData.company_mobile}
                              onChange={handleInput}
                              className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                            />
                          </div>

                        </div>


                        <div className="grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-x-3 gap-y-4">
                          <div className="flex flex-col gap-1">
                            <label className="text-black text-sm font-medium">
                              {words['Support Email']}
                            </label>
                            <input
                              type="email"
                              name="support_email"
                              value={formData.support_email}
                              onChange={handleInput}
                              className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                            />
                          </div>

                          <div className="flex flex-col gap-1">
                            <label className="text-black text-sm font-medium">
                              {words['Company Whatsapp']}
                            </label>
                            <input
                              // type="email"
                              name="company_whatsapp"
                              value={formData.company_whatsapp}
                              onChange={handleInput}
                              className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <label className="text-black text-sm font-medium">
                              {words['Company X']}
                            </label>
                            <input
                              // type="email"
                              name="company_X"
                              value={formData.company_X}
                              onChange={handleInput}
                              className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <label className="text-black text-sm font-medium">
                              {words['Company Instagram']}
                            </label>
                            <input
                              // type="email"
                              name="company_instagram"
                              value={formData.company_instagram}
                              onChange={handleInput}
                              className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                            />
                          </div>
                        </div>

                        {/* <div className="flex flex-col gap-1">
                          <label className="text-black text-sm font-medium">
                            {words['Login Image']}
                          </label>
                          <div
                            className="img-box flex items-center justify-center h-20 w-full bg-[#FBFBFB] border border-[#D9D9D9] rounded-lg relative cursor-pointer"
                            onClick={() =>
                              document
                                .getElementById("upload_login_image")
                                .click()
                            }
                          >
                            {loginImage ? (
                              <img
                                alt="img"
                                src={URL.createObjectURL(loginImage)}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            ) : (
                              <img
                                alt="img"
                                src={`${BASE_URL}/${formData.login_image}`}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              name="image"
                              id="upload_login_image"
                              className="hidden cleanbtn"
                              onChange={(e) => {
                                setLoginImage(e.target.files[0]);
                              }}
                            />
                          </div>

                        </div> */}
                      </div>
                    </div>

                  </div>

                  <div className="flex items-center justify-end">
                    <button
                      className="btn bg-themeColor"
                      onClick={(e) => handleSubmitSetting()}
                    >
                      Save
                    </button>
                  </div>
                </>
              ) : (
                <div className="setting-grid w-full grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
                  <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3 h-96 overflow-hidden">
                    <h1 className="text-black text-base font-medium ">
                      {words['Terms & Condition']}
                    </h1>
                    <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                    <TextEditor
                      initialContent={custTerms}
                      onChange={(text) => setCustTerms(text)}
                    />


                  </div>


                  <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3 h-96 overflow-hidden">
                    <h1 className="text-black text-base font-medium ">
                      {words['Privacy policy']}
                    </h1>
                    <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                    <TextEditor
                      initialContent={custPrivacy}
                      onChange={(text) => setCustPrivacy(text)}
                    />
                  </div>



                  <div className="flex items-center justify-end">
                    <button
                      className="btn bg-themeColor"
                      onClick={(e) => handleSubmitSetting()}
                    >
                      Save
                    </button>
                  </div>
                </div>

              )}
            </div>

          </div>
        </div>
      </Layout>
    </>


  );
};

export default Setting;




import moment from 'moment-timezone';

import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
    DotMenuIcon,
    TrashIcon,
    EyeIcon2,
    EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import AddUserModel from "../../components/AddUserModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { Link, useNavigate } from "react-router-dom";
import { getDateString } from "../../utils/functions";
import { Dropdown, Menu, Button, Image } from "antd";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { collection, firestore, getDocs, addDoc, updateDoc, doc } from "../../firebase";

const UsersPosts = () => {
    const words = useSelector((state) => state.authReducer.words);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [usersTemp, setUserTemp] = useState([]);
    const [deletedId, setDeletedId] = useState("");

    const columns = [
        {
            title: words["Id"],
            dataIndex: "id",
            key: "id",
        },
        {
            title: words["Image"],
            dataIndex: "image",
            key: "image",
            render: (_, record) => (
                <Image
                    src={record.photo_url}
                    style={{ width: 50, height: 50, borderRadius: 50 / 2 }}
                />
            )
        },
        {
            title: words["Title"],
            dataIndex: "display_name",
            key: "display_name",
        },
        {
            title: words["Description"],
            dataIndex: "display_name",
            key: "display_name",
        },
        {
            title: words["Status"],
            dataIndex: "display_name",
            key: "display_name",
            render: (_, record) => (
                <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                    <div
                        className={`py-1 px-2 rounded-lg text-center font-semibold border ${record.status === "Complete"
                            ? "bg-[#0DB214]/40 text-[#0DB214] border-[#0DB214]"
                            : record.status === "Progress"
                                ? "bg-[#4F52FF]/40 text-[#4F52FF] border-[#4F52FF]"
                                : "bg-[#FFC005]/40 text-[#FFC005] border-[#FFC005]"
                            }`}
                    >
                        {record.status}
                    </div>
                </td>
            )
        },




        {
            title: words["Action"],
            key: "action",
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu
                            onClick={({ key }) => {
                                if (key == "view") {
                                    navigate("/dashboard/user-details/" + record.id);
                                } else if (key == "delete") {
                                    setDeletedId(record.id);
                                    setOpenDeleteModal(true);
                                }
                            }}
                        >
                            <>
                                <Menu.Item key="view">{words["View"]}</Menu.Item>
                            </>
                        </Menu>
                    }
                    trigger={["click"]}
                >
                    <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
                        <DotMenuIcon />
                    </div>

                </Dropdown>
            ),
        },
    ];




    return (


        <div>
            <DataTable
                searchContainerStyle={{ width: "40%" }}
                data={users}
                columns={columns}
            />
        </div >

    );
};

export default UsersPosts;


import React, { useState } from "react";
import { CrossIcon, EditIcon, ImageIcon } from "../assets/Icons";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { collection, firestore, getDocs, addDoc, storage, uploadFile, updateDoc, doc, deleteDoc, storageRef, deleteObject } from "../firebase";


import ImageUploader from "./ImageUploader";


const EditProductModel = ({
  openModal,
  setOpenModal,
  onSuccessCreate = () => { },
  categories = [],
  data = {},
}) => {

  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);
  const textAlign = lang == 'ar' ? 'text-right' : 'text-left';
  const [loading, setLoading] = useState(false);


  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [price, setPrice] = useState(data.price);
  const [salePrice, setSalePrice] = useState(data.salePrice);
  const [category, setCategory] = useState(data.category);
  const [images, setImages] = useState(data.images);
  const [deletedImgs, setDeletedImgs] = useState([]);





  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...selectedImages]);
  };


  const onDeleteImage = (index, item) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));

    if (typeof item.imageUrl == 'string' && item?.imageUrl.startsWith('https://')) {
      let arr = deletedImgs;
      arr.push(item);
      setDeletedImgs(arr);
    }
  }

  const doEditProduct = async () => {


    if (!name) return toast.error('Please enter Category name');
    if (!description) return toast.error('Please enter product description');
    if (!price) return toast.error('Please enter a valid product price');
    if (!salePrice) return toast.error('Please enter a valid product sale price');
    if (!category) return toast.error('Please select product category');
    if (images.length == 0) return toast.error('Please upload at least one image');

    const newImages = images.filter(item => !item.imageUrl?.startsWith('https://'));


    try {
      setLoading(true);

      const productData = {
        name,
        description,
        price,
        salePrice,
        category,
        updatedAt: new Date().toISOString(),
      };

      // Step 1: Edit product
      const docRef = doc(firestore, 'products', data?.id);
      await updateDoc(docRef, productData);



      const productId = data.id;

      // Step 2: Upload images to Firebase Storage using the uploadFile function and get download URLs
      const imageUrls = await Promise.all(newImages.map(async (image, index) => {
        const filePath = `products/${productId}/image_${index}`;  // Construct the file path
        const downloadURL = await uploadFile(image, filePath);  // Use uploadFile function to upload and get URL
        return downloadURL;
      }));

      // Step 3: Insert image paths into Firestore
      const imageCollectionRef = collection(firestore, 'product_images');

      await Promise.all(imageUrls.map(async (url) => {
        const imageData = {
          productId,  // Link the product ID with the image
          imageUrl: url,
          createdAt: new Date().toISOString(),
        };
        await addDoc(imageCollectionRef, imageData);
      }));

      // Step 4: Delete from the product_images

      for (let key of deletedImgs) {
        console.log('the key .id', key.id);
        let ref = doc(firestore, 'product_images', key.id);
        await deleteDoc(ref);
      }

      // Step 5: Delete the images from the storage      

      // THE OBJECT IN THE images array is as follows:
      // createdAt:  "2024-08-27T11:14:31.378Z"
      // id:  "g7e10oEUmMxaAM5Xrzzd"
      // imageUrl: "https://firebasestorage.googleapis.com/v0/b/magicpagessoical-ir4lr4.appspot.com/o/products%2FBDWdDVcN6wBbZtzvt96i%2Fimage_0?alt=media&token=dc683cd2-67b1-486c-afce-d90e7c3e03a0"
      // productId: "BDWdDVcN6wBbZtzvt96i"

      await Promise.all(deletedImgs.map(async (img) => {
        const imagePath = decodeURIComponent(img.imageUrl.split('/o/')[1].split('?')[0]); // Extract the file path from imageUrl
        console.log('imagePath', imagePath)
        const imageRef = storageRef(storage, imagePath); // Create a reference to the image
        console.log('the image ref',imageRef);
        const res = await deleteObject(imageRef);  // Delete the image from storage
        console.log('the delete res', res);
      }));

      setLoading(false);
      onSuccessCreate(productId);  // Trigger the success callback with the product ID

    } catch (e) {
      console.error(e);
      setLoading(false);
    }


  };

  return (
    <div
      dir={lang == 'ar' ? "rtl" : "ltr"}
      className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      <div className="flex items-center justify-between gap-3">
        <h1 className="text-black text-xl font-medium">
          {words["Edit Product"]}
        </h1>
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>
      <div className="flex flex-col gap-5 mt-10">

        <ImageUploader
          onUploadImages={handleImageChange}
          images={images}
          onDeleteImage={(index, item) => onDeleteImage(index, item)}
        />

        <div className="flex flex-col gap-2">
          <label
            className={` ${textAlign} text-black text-sm font-normal `}>
            {words["Name"]}
          </label>
          <input

            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className={"border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className={` ${textAlign} text-black text-sm font-normal `}>
            {words["Description"]}
          </label>
          <input

            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            className={"border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label
            className={` ${textAlign} text-black text-sm font-normal `}>
            {words["Price"]}
          </label>
          <input

            value={price}
            onChange={(e) => setPrice(e.target.value)}
            type="text"
            className={"border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label
            className={` ${textAlign} text-black text-sm font-normal `}>
            {words["Sale price"]}
          </label>
          <input

            value={salePrice}
            onChange={(e) => setSalePrice(e.target.value)}
            type="text"
            className={"border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">
            {words["Category"]}
          </label>
          <select
            onChange={(e) => setCategory(e.target.value)}
            type="text"
            placeholder="Select category"
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          >
            <option disabled selected value="">{words["Select category"]}</option>
            {categories.map((d, i) => (
              <option key={i} value={d.name}>
                {d.name}
              </option>
            ))}
          </select>
        </div>




        <div className="flex items-center justify-center">
          <button
            onClick={() => (loading ? null : doEditProduct())}
            className="button rounded-full !py-2 !px-10"
          >
            {loading ? (
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-white-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              words["Save"]
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProductModel;

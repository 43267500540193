import React, { useState, useEffect } from "react";
import Tooltip from '@mui/material/Tooltip';


import Layout from "../../components/Layout";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";

import Modal from "../../components/Modal";

import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getDateString } from "../../utils/functions";
import { Dropdown, Menu, Button } from "antd";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import AddSeminarModal from "../../components/AddSeminarModel";
import { collection, firestore, getDocs, addDoc, deleteDoc, doc } from "../../firebase";
import AppLoader from "../../components/Loader/AppLoader";

const Seminars = () => {
  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);
  const isAr = lang == 'ar';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [users, setUsers] = useState([]);

  const [seminars, setSeminars] = useState([]);
  const [deletedId, setDeletedId] = useState("");

  const columns = [
    // {
    //   title: words["Id"],
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: words["Seminar Name"],
      dataIndex: isAr ? "titleAr" : "titleEn",
      key: "name",
    },
    {
      title: words["Description"],
      dataIndex: isAr ? "descAr" : "descEn",
      key: "number",
      render: (_, record) => (
        <Tooltip
          title={isAr ? record.descAr : record.descEn}
          arrow
        >
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: 100,
              overflow: 'hidden',
              whiteSpace: 'nowrap', // To ensure text doesn't wrap
              textOverflow: 'ellipsis' // To show ellipsis when text overflows
            }}
          >
            {isAr ? record.descAr : record.descEn}
          </div>
        </Tooltip>
      ),
    },
    {
      title: words["Duration"],
      dataIndex: "type",
      key: "type",
    },
    {
      title: words["URL Link"],
      dataIndex: "url",
      key: "url",
    },
    {
      title: words["Date start"],
      dataIndex: "dateStart",
      key: "dateStart",
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {getDateString(record.dateStart)}
          </div>
        </div>
      ),
    },
    {
      title: words["Type"],
      dataIndex: "type",
      key: "type",

    },



    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                setDeletedId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              <>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>
        </Dropdown>
      ),
    },
  ];

  const getSiminars = async () => {


    setLoading(true);

    try {
      const collectionRef = collection(firestore, 'seminars');
      const snapshot = await getDocs(collectionRef);

      const docs = snapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id
        }

      });

      setSeminars(docs);
      setLoading(false);
      console.log('docs', docs)
      // setData(docs);

    }
    catch (err) {
      setLoading(false);
      toast.error(err);
    }

  };

  const doDeleteUser = async () => {
    try {
      setLoading(true)
      const docRef = doc(firestore, 'seminars', deletedId);
      await deleteDoc(docRef);
      toast.success('Record deleted successfully');
      getSiminars();


    } catch (e) {
      setLoading(false)
      console.log(e)
      toast.error('Error deleting document: ', e);
    }

  };


  useEffect(() => {
    getSiminars();
  }, [])


  return (
    <>
      {
        loading &&
        <AppLoader />
      }

      <Layout pageTitle={words["Seminars"]}>

        <div className="store-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                <div className="flex flex-1 justify-end">
                  <button
                    style={{ zIndex: 1 }}
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">
                      {words["Add"]}
                    </label>
                  </button>
                </div>
              </div>

              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={seminars}
                  columns={columns}
                />
              </div>
            </div>
          </div>
          <Modal
            // width="max-w-4xl"
            open={openModal} setOpen={setOpenModal}>
            <AddSeminarModal
              onSuccessCreate={() => {
                setOpenModal(false);
                getSiminars();
              }}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
          <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
              onSubmit={() => {
                doDeleteUser();
              }}
            />
          </Modal>
        </div>
      </Layout>
    </>
  );
};

export default Seminars;

import { GalleryIcon } from "../assets/Icons";

function UploadButton({ label = "Choose File", text = "", ...rest }) {
  return (
    <button
      type="button"
      className="relative block w-full rounded-lg border-[1px] bg-[#EFEFEF] border-[#808080] p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-[#808080]/40 focus:ring-offset-2"
      {...rest}
    >
      <div className="h-10 w-10 m-auto text-[#808080]">
        <GalleryIcon />
      </div>
      {label && (
        <span className="mt-2 block text-sm font-semibold text-gray-700">
          {label}
        </span>
      )}
      {text && <p className="mt-2.5 text-sm text-[#808080]">{text}</p>}
    </button>
  );
}

export default UploadButton;

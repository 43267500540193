import React, { useState, useEffect } from "react";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import { routes } from "../../Api/routes";
import { postRequest } from "../../Api";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable";
import { Dropdown, Menu, Button, Typography } from "antd";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import AppLoader from "../../components/Loader/AppLoader";
import Modal from "../../components/Modal";
// import AddPostModel from "../../components/AddPostModal";
import RejectedReasonModel from "../../components/RejectedReasonModel";
import { useSelector } from "react-redux";
import { Image } from 'antd';
import { useNavigate } from "react-router-dom";
import AddCategoryModel from "../../components/AddCategoryModel";
import { collection, firestore, getDocs, addDoc, deleteDoc, doc } from "../../firebase";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import EditCategoryModel from "../../components/EditCategoryModel";

const CategoriesTable = ({
  loadingState = () => { },
  data, refreshData = () => { }
}) => {
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editItem, setEditItem] = useState();
  const [categories, setCategories] = useState([]);
  const [deletedId, setDeletedId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);



  const columns = [

    {
      title: words["Name"],
      dataIndex: "name",
      key: "name",
    },


    {
      title: words["Created at"],
      // dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(typeof record.createdAt == 'string' && JSON.parse(record.createdAt))}
        </div>
      ),
    },
    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {

                if (key == 'delete') {
                  setDeletedId(record.id);
                  setOpenDeleteModal(true);
                }
                if (key == 'edit') {
                  setEditItem(record);
                  setEditModal(true);
                }
              }}
            >
              <>
                <Menu.Item key="edit">{words["Edit"]}</Menu.Item>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="flex items-center  cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>

        </Dropdown>
      ),
    },
  ];





  // const getCategories = async () => {
  //   loadingState(true);
  //   try {
  //     const collectionRef = collection(firestore, 'productCategories');
  //     const snapshot = await getDocs(collectionRef);

  //     const docs = snapshot.docs.map(doc => {
  //       return {
  //         ...doc.data(),
  //         id: doc.id
  //       }

  //     });

  //     setCategories(docs);
  //     loadingState(false);

  //   }
  //   catch (err) {
  //     loadingState(false);
  //     toast.error(err);
  //   }

  // };

  const doDeleteCategory = async () => {
    try {
      loadingState(true)
      const docRef = doc(firestore, 'productCategories', deletedId);
      await deleteDoc(docRef);
      toast.success('Record deleted successfully');
      refreshData();


    } catch (e) {
      loadingState(false)
      console.log(e)
      toast.error('Error deleting document: ', e);
    }

  };



  // useEffect(() => {
  //   refreshData();
  // }, [])

  return (
    <>

      <div className="flex flex-col w-full gap-4">
        <div className="flex items-center justify-between gap-3">

          <div className="flex flex-1 justify-end">
            <div
              style={{ zIndex: 1 }}
              onClick={() => setOpenModal(true)}
              className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1"
            >
              <div className="flex items-center justify-center text-white font-normal text-2xl">
                +
              </div>
              <label className="text-white cursor-pointer">{words["Add"]}</label>
            </div>
          </div>


        </div>

        <div style={{ marginTop: -50 }}>
          <DataTable
            searchContainerStyle={{ width: "40%" }}
            data={data}
            columns={columns}
          />
        </div>

        <Modal
          open={openModal} setOpen={setOpenModal}>
          <AddCategoryModel
            onSuccessCreate={() => {
              setOpenModal(false);
              refreshData();
            }}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </Modal>
        <Modal
          open={editModal} setOpen={setEditModal}>
          <EditCategoryModel
            onSuccessCreate={() => {
              setEditModal(false);
              refreshData();
            }}
            loadingState={loadingState}
            data={editItem}
            openModal={openModal}
            setOpenModal={setEditModal}
          />
        </Modal>

        <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
          <DeleteConfirmationModel
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            onSubmit={() => {
              doDeleteCategory();
            }}
          />
        </Modal>



      </div>
    </>
  );
};

export default CategoriesTable;

import React from "react";

function EyeIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0013 24.0001C9.27864 24.0094 2.66797 20.4188 2.66797 16.0001C2.66797 11.5814 9.3133 7.97744 16.0013 8.00011C22.6893 8.02277 29.3346 11.5814 29.3346 16.0001C29.3346 20.4188 22.724 23.9908 16.0013 24.0001ZM16.0013 21.3334C17.4158 21.3334 18.7723 20.7715 19.7725 19.7713C20.7727 18.7711 21.3346 17.4146 21.3346 16.0001C21.3346 14.5856 20.7727 13.2291 19.7725 12.2289C18.7723 11.2287 17.4158 10.6668 16.0013 10.6668C14.5868 10.6668 13.2303 11.2287 12.2301 12.2289C11.2299 13.2291 10.668 14.5856 10.668 16.0001C10.668 17.4146 11.2299 18.7711 12.2301 19.7713C13.2303 20.7715 14.5868 21.3334 16.0013 21.3334ZM16.0013 18.6668C15.2941 18.6668 14.6158 18.3858 14.1157 17.8857C13.6156 17.3856 13.3346 16.7073 13.3346 16.0001C13.3346 15.2929 13.6156 14.6146 14.1157 14.1145C14.6158 13.6144 15.2941 13.3334 16.0013 13.3334C16.7085 13.3334 17.3868 13.6144 17.8869 14.1145C18.387 14.6146 18.668 15.2929 18.668 16.0001C18.668 16.7073 18.387 17.3856 17.8869 17.8857C17.3868 18.3858 16.7085 18.6668 16.0013 18.6668Z" fill="#8434FC" />
    </svg>

  );
}

export default EyeIcon;

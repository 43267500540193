import React from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const Layout = ({ children, pageTitle }) => {

  const lang = useSelector((state) => state.authReducer.lang);
  const { openSidebar } = useSelector((state) => state.globalReducer);

  return (
    <div
      style={{ marginLeft: !openSidebar ? 0 : lang == 'ar' ? 0 : 280, marginRight: !openSidebar ? 0 : lang == 'en' ? 0 : 280 }}
      className={`dashboard-page flex flex-col relative p-2 ${openSidebar ? "" : "full-open"
        }`}
    >
      <Sidebar openSidebar={openSidebar} />
      <div className="pages-block flex flex-col relative h-full">
        <Header pageTitle={pageTitle} />
        <section>{children}</section>
        {/* <Footer /> */}
      </div>
    </div>
  );
};
export default Layout;

import React from 'react';

const Stages = () => {
    const stages = [
        {
            stage: 'Infancy',
            tasks: [
                'User has created an account',
                'User has engaged with basic platform features (e.g., purchased a notebook)',
                'Percentage of completed profile information',
                'Frequency of App logins',
            ],
            status: [true, true, true, true],
        },
        {
            stage: 'Developing',
            tasks: [
                'User has participated in multiple webinars',
                'User regularly engages with the community',
                'Number of webinar attendances',
                'Frequency and depth of community interactions',
            ],
            status: [true, true, true, true],
        },
        {
            stage: 'Established',
            tasks: [
                'Uses career planning tools',
                'Sets and tracks personalized goals',
                'Completion of career planning assessments',
                'Number of goals set and achieved',
            ],
            status: [true, true, true, true],
        },
        {
            stage: 'Advanced',
            tasks: [
                'User demonstrates consistent progress in career planning',
                'User mentors or engages with others in the community',
                'Progression in career planning assessments over time',
                "User's role in community interactions (e.g., mentorship, sharing experiences)",
            ],
            status: [true, true, true, true],
        },
        {
            stage: 'Leading',
            tasks: [
                'User actively contributes to the entrepreneurial community',
                "User's goals align with long-term visions and strategic plans",
                'Contributions to community content (e.g., articles, success stories)',
                "Alignment of user's goals with broader entrepreneurial and personal development strategies",
            ],
            status: [false, false, false, false],
        },
    ];

    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ backgroundColor: '#F2F8FF' }}>
                        <th style={{ textAlign: 'left', padding: '10px', fontFamily: 'PMe' }}>All Stages</th>
                        <th style={{ textAlign: 'left', padding: '10px', fontFamily: 'PMe' }}>Task</th>
                        <th style={{ textAlign: 'center', padding: '10px', fontFamily: 'PMe' }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {stages.map((stage, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td style={{ fontWeight: 'bold', padding: '10px', borderTop: '1px solid #E6E6E6', color: '#8434FC', fontFamily: 'PMe' }}>{stage.stage}</td>
                                <td style={{ padding: '10px', borderTop: '1px solid #E6E6E6' }}></td>
                                <td style={{ padding: '10px', borderTop: '1px solid #E6E6E6' }}></td>
                            </tr>
                            {stage.tasks.map((task, taskIndex) => (
                                <tr key={taskIndex}>
                                    <td style={{ padding: '10px', borderTop: taskIndex === 0 ? 'none' : '1px solid #E6E6E6' }}></td>
                                    <td style={{ padding: '10px', borderTop: taskIndex === 0 ? 'none' : '1px solid #E6E6E6', fontFamily: 'PRe', fontSize: 14 }}>{task}</td>
                                    <td style={{ padding: '10px', textAlign: 'center', borderTop: taskIndex === 0 ? 'none' : '1px solid #E6E6E6' }}>
                                        {stage.status[taskIndex] ? (
                                            <span>
                                                <div style={{ width: 20, height: 20, borderRadius: 20 / 2, backgroundColor: '#8434FC', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                    <svg width="10" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.6723 0.225302C12.976 -0.0751008 13.4685 -0.0751008 13.7722 0.225302C14.0526 0.502598 14.0741 0.938929 13.8369 1.24069L13.7722 1.31316L5.21664 9.7747C4.93626 10.052 4.49508 10.0733 4.18997 9.83869L4.11669 9.7747L0.227806 5.92854C-0.0759353 5.62814 -0.0759353 5.14109 0.227806 4.84069C0.508182 4.56339 0.949362 4.54206 1.25448 4.7767L1.32775 4.84069L4.66667 8.14231L12.6723 0.225302Z" fill="white" />
                                                    </svg>
                                                </div>

                                            </span>
                                        ) : (
                                            <div style={{ color: '#D3D3D3', fontSize: '24px' }}>⚪</div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Stages;

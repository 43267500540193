import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
  KeyIcon,
  MailIcon,
  PhoneIcon,
  UserIcon,
  UsersIcon,
} from "../../assets/Icons";

const FinanceDetail = () => {
  return (
    <Layout pageTitle="Finance Detail">
      <div className="finance-detail-page min-h-screen flex">
        <div className="wrap wrapWidth flex sm:flex-row flex-col gap-8">
          <div className="left-side flex flex-col gap-6 flex-[0.5]">
            <div className="client-info flex flex-col rounded-xl">
              <div className="w-full p-3 border-b border-[#BEBABA]">
                <h1 className="text-[#43434C] text-lg font-medium">
                  Client Information
                </h1>
              </div>
              <div className="flex flex-col p-3">
                <div className="flex items-center gap-2 py-3 border-b border-[#EBF0ED]">
                  <div className="flex items-center justify-center bg-[#FAFAFA] border border-[#EBF0ED] p-2 rounded-lg">
                    <UserIcon />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h1 className="text-black text-base font-medium">
                      Name :{" "}
                    </h1>
                    <h3 className="text-black text-sm font-light">
                      Mohammed Al Jaafari
                    </h3>
                  </div>
                </div>
                <div className="flex items-center gap-2 py-3 border-b border-[#EBF0ED]">
                  <div className="flex items-center justify-center bg-[#FAFAFA] border border-[#EBF0ED] p-2 rounded-lg">
                    <PhoneIcon />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h1 className="text-black text-base font-medium">
                      Phone :
                    </h1>
                    <h3 className="text-black text-sm font-light">
                      +968- 94449520
                    </h3>
                  </div>
                </div>
                <div className="flex items-center gap-2 py-3 border-b border-[#EBF0ED]">
                  <div className="flex items-center justify-center bg-[#FAFAFA] border border-[#EBF0ED] p-2 rounded-lg">
                    <MailIcon />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h1 className="text-black text-base font-medium">
                      Email :
                    </h1>
                    <h3 className="text-black text-sm font-light">
                      maljaafari70@gmail.com
                    </h3>
                  </div>
                </div>
                <div className="flex items-center gap-2 py-3">
                  <div className="flex items-center justify-center bg-[#FAFAFA] border border-[#EBF0ED] p-2 rounded-lg">
                    <KeyIcon />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h1 className="text-black text-base font-medium">
                      User ID :
                    </h1>
                    <h3 className="text-black text-sm font-light">#U3123</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="client-info flex flex-col items-center justify-center rounded-xl p-5 gap-4">
              <button className="btn bg-themeColor rounded-lg text-white w-full">
                Print
              </button>
              <button className="btn bg-[#E9ECEF] rounded-lg text-[#6C757D] w-full">
                Download
              </button>
            </div>
          </div>
          <div className="right-side flex flex-col gap-8 flex-1">
            <h5 className="text-sm text-[#3D475C]">Invoice Details - #3844</h5>
            <div className="invoice-detail flex flex-col  items-start px-6 py-8 border border-[#EAEAEA] rounded-lg gap-6">
              <div className="flex items-center justify-center">
                <img src="/images/favicon.svg" className="" />
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col gap-2">
                  <h1 className="text-black text-base font-semibold w-max">
                    Emtnan Invoice
                  </h1>
                  <p className="text-black/40 text-sm font-light w-max">
                    Invoice ID: <span className="text-black">INV-098</span>
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-black/40 text-sm font-light w-max">
                    123 Sisipi St
                  </p>
                  <p className="text-black/40 text-sm font-light w-max">
                    Toronto
                  </p>
                  <p className="text-black/40 text-sm font-light w-max">
                    California (CA), 1234
                  </p>
                </div>
              </div>
              <div className="bg-[#8434FC]/20 p-5 flex justify-between w-full rounded-xl">
                <div className="flex flex-col gap-2">
                  <h1 className="text-black/60 text-xs">INVOICE DETAILS</h1>
                  <h1 className="text-black font-medium text-sm my-2">
                    INV-10839
                  </h1>
                  <h1 className="text-black/60 text-xs">
                    Issue Date: August 9, 2022
                  </h1>
                  <h1 className="text-black/60 text-xs">
                    Due Date: August 9, 2022
                  </h1>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="text-black/60 text-xs text-end">BILLED TO</h1>
                  <h1 className="text-black font-medium text-sm my-2 text-end">
                    Mohammed Al Jaafari
                  </h1>
                  <h1 className="text-black/60 text-xs text-end">
                    123 Sisipi St
                  </h1>
                  <h1 className="text-black/60 text-xs text-end">Toronto</h1>
                  <h1 className="text-black/60 text-xs text-end">
                    California (CA), 1234
                  </h1>
                </div>
              </div>
              <div className="items-tbl flex flex-col w-full">
                <div className="tbl-row flex items-center py-3 border-b border-[#F0F0F0]">
                  <div className="row-item flex flex-1 text-black/60 text-xs">
                    Item
                  </div>
                  <div className="row-item flex flex-1 text-black/60 text-xs">
                    Quantity
                  </div>
                  <div className="row-item flex flex-1 text-black/60 text-xs">
                    Amount
                  </div>
                  <div className="row-item flex flex-[0.4] text-black/60 text-xs">
                    Total
                  </div>
                </div>
                <div className="tbl-row flex items-center py-3 border-b border-[#F0F0F0]">
                  <div className="row-item flex flex-1 text-black/70 font-medium text-xs">
                    Ads in ()
                  </div>
                  <div className="row-item flex flex-1 text-black/60 text-xs">
                    1 Month
                  </div>
                  <div className="row-item flex flex-1 text-black/60 text-xs">
                    15 OMR
                  </div>
                  <div className="row-item flex flex-[0.4] text-black/70 font-medium text-xs">
                    15 OMR
                  </div>
                </div>

                <div className="tbl-row flex items-center py-3 bg-[#FAFAFA] mt-10 rounded-lg">
                  <div className="row-item flex flex-1 text-black/70 font-medium text-xs"></div>
                  <div className="row-item flex flex-1 text-black/60 text-xs"></div>
                  <div className="row-item flex flex-1 text-black/60 text-base">
                    Total Amount:
                  </div>
                  <div className="row-item flex flex-1 text-black/70 font-medium text-xs">
                    15.500 OMR
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <p className="text-center text-[#A0A0A0] text-xs w-[80%] font-light">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FinanceDetail;
